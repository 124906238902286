<template>
  <div>
    <v-btn
      class="btn-insertion-bdd"
      v-if="phase == 2 && checkPermission('IBD')"
      @click="handleUploadToExcel('show')"
      >Insertion BDD</v-btn
    >
    <v-tooltip bottom color="#311B92" v-if="phase == 1">
      <template v-slot:activator="{ on, attrs }">
        <div
          size="sm"
          class="block-icon mr-2"
          v-on="on"
          v-bind="attrs"
          @click="handleUploadToExcel('show')"
        >
          <font-awesome-icon icon="file-import" />
        </div>
      </template>
      <span>Insertion xls</span>
    </v-tooltip>

    <!-- INSERTION XLS -->
    <v-dialog
      v-model="insertionXls"
      max-width="1200px"
      scrollable
      hide-overlay
      persistent
      content-class="custom-vuetify-dialog-insertion"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">{{ title }}</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="handleUploadToExcel('hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card" @change="handleUploadFile">
          <div class="box-white">
            <div class="box-title-uplaod mt-4">
              <span class="text">Importer un fichier</span>
            </div>
            <v-row align-h="center">
              <div class="container" @drop="handleFileDrop">
                <div class="file-wrapper">
                  <input
                    type="file"
                    name="file-input"
                    id="uploadInput"
                    :class="{ display: this.getLoadingUploadFileCdg }"
                    @change="handleFileInput"
                  />
                  <div class="upload_label">
                    <img
                      src="@/assets/Icon feather-upload.png"
                      class="icon-upload"
                    />
                    <p class="color-black mb-2">
                      <span class="drag-title"><u>Click to upload</u></span> or
                      drag and drop
                    </p>
                    <p class=" mt-0 mb-0">Excel (xlsx) (max, 3MB)</p>
                  </div>
                  <div class="upload_label">
                    <p class=" drag-title" v-if="this.file">
                      {{ this.file.name }}
                    </p>
                  </div>
                </div>
              </div>
              <v-overlay :value="getLoadingUploadFileCdg">
                <v-progress-circular
                  indeterminate
                  size="64"
                  color="#704ad1"
                ></v-progress-circular>
              </v-overlay>
            </v-row>
          </div>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>

          <v-btn text @click.prevent="handleUploadToExcel('hide')">
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--RESULTAT INJECTION-->
    <v-dialog
      v-model="uploadFileCdgModal"
      max-width="1200px"
      scrollable
      hide-overlay
      persistent
      content-class="custom-vuetify-dialog-insertion"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">RÉSULTAT D'INJECTION</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="handleUploadFileCdg('hide', true)"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card">
          <v-row
            v-if="
              getReturnDataInjection &&
                ((getReturnDataInjection.undecided &&
                  getReturnDataInjection.undecided.length) ||
                  (getReturnDataInjection.new &&
                    getReturnDataInjection.new.length))
            "
          >
            <v-col
              cols="6"
              v-if="
                getReturnDataInjection &&
                  getReturnDataInjection.undecided &&
                  getReturnDataInjection.undecided.length
              "
              class="mt-1"
            >
              <span class="title-modal-injection"
                >Liste des dossiers existe :</span
              >
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center text-dark"></th>
                      <th class="text-center text-dark"></th>
                      <th class="text-center text-dark">
                        <v-checkbox
                          class="checked-global-rapport"
                          v-model="checkAll"
                          :disabled="checkAll == 'ignore'"
                          @change="changeAllType"
                          color="#704ad1"
                          value="ignore"
                          hide-details
                        ></v-checkbox>
                      </th>
                      <th class="text-center text-dark">
                        <v-checkbox
                          class="checked-global-rapport"
                          v-model="checkAll"
                          :disabled="checkAll == 'replace'"
                          @change="changeAllType"
                          color="#704ad1"
                          value="replace"
                          hide-details
                        ></v-checkbox>
                      </th>
                      <th class="text-center text-dark">
                        <v-checkbox
                          class="checked-global-rapport"
                          v-model="checkAll"
                          :disabled="checkAll == 'version'"
                          @change="changeAllType"
                          color="#704ad1"
                          value="version"
                          hide-details
                        ></v-checkbox>
                      </th>
                    </tr>
                    <tr>
                      <th class="text-center text-dark"></th>
                      <th class="text-center text-dark">Numéro de dossier</th>
                      <th class="text-center text-dark">
                        Ignoré
                      </th>
                      <th class="text-center text-dark">
                        Remplacé
                      </th>
                      <th class="text-center text-dark">
                        Versionné
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in getReturnDataInjection.undecided"
                      :key="'frais' + index + item.id"
                    >
                      <td class="text-center">
                        <font-awesome-icon
                          icon="eye"
                          @click="openModelDetails(item)"
                        />
                      </td>
                      <td class="text-center">{{ item.numero_dossier }}</td>
                      <td class="text-center">
                        <v-checkbox
                          class="checked-global-rapport"
                          v-model="item.decision"
                          @change="checkDecision"
                          color="#704ad1"
                          value="ignore"
                          :disabled="item.decision == 'ignore'"
                          hide-details
                        ></v-checkbox>
                      </td>
                      <td class="text-center">
                        <v-checkbox
                          class="checked-global-rapport"
                          v-model="item.decision"
                          @change="checkDecision"
                          color="#704ad1"
                          value="replace"
                          :disabled="item.decision == 'replace'"
                          hide-details
                        ></v-checkbox>
                      </td>
                      <td class="text-center">
                        <v-checkbox
                          class="checked-global-rapport"
                          v-model="item.decision"
                          @change="checkDecision"
                          color="#704ad1"
                          value="version"
                          :disabled="item.decision == 'version'"
                          hide-details
                        ></v-checkbox>
                      </td>
                    </tr>
                    <tr v-if="getReturnDataInjection.undecided.length == 0">
                      <td>
                        Il n'y a aucun enregistrement à afficher
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col
              cols="6"
              class="mt-1"
              v-if="
                getReturnDataInjection &&
                  getReturnDataInjection.new &&
                  getReturnDataInjection.new.length
              "
            >
              <span class="title-modal-injection"
                >Liste des dossiers a injecté :</span
              >
              <div
                v-for="(i, index) in getReturnDataInjection.new"
                :key="'succes-file' + index"
              >
                <span class="mr-2">•</span>
                <span class="item-in-modal-injection"> {{ i }}</span>
              </div>
            </v-col>
            <v-col
              cols="6"
              class="mt-1"
              v-if="
                getReturnDataInjection &&
                  getReturnDataInjection.no_organisme &&
                  getReturnDataInjection.no_organisme.length
              "
            >
              <span class="title-modal-injection"
                >Liste des dossiers sans organisme :</span
              >
              <div
                v-for="(i, index) in getReturnDataInjection.no_organisme"
                :key="'no-organisme' + index"
              >
                <span class="mr-2">•</span>
                <span class="item-in-modal-injection"> {{ i }}</span>
              </div>
            </v-col>
            <v-col
              cols="6"
              class="mt-1"
              v-if="
                getReturnDataInjection &&
                  getReturnDataInjection.no_installateur &&
                  getReturnDataInjection.no_installateur.length
              "
            >
              <span class="title-modal-injection"
                >Liste des dossiers sans installateur :</span
              >
              <div
                v-for="(i, index) in getReturnDataInjection.no_installateur"
                :key="'no-installateur' + index"
              >
                <span class="mr-2">•</span>
                <span class="item-in-modal-injection"> {{ i }}</span>
              </div>
            </v-col>
            <v-col
              cols="6"
              class="mt-1"
              v-if="
                getReturnDataInjection &&
                  getReturnDataInjection.no_agent_commercial &&
                  getReturnDataInjection.no_agent_commercial.length
              "
            >
              <span class="title-modal-injection"
                >Liste des dossiers sans agent commercial :</span
              >
              <div
                v-for="(i, index) in getReturnDataInjection.no_agent_commercial"
                :key="'no-agent-commercial' + index"
              >
                <span class="mr-2">•</span>
                <span class="item-in-modal-injection"> {{ i }}</span>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn text @click.prevent="handleUploadFileCdg('hide', true)">
            Fermer
          </v-btn>
          <v-btn
            color="#704ad1"
            text
            @click.prevent="injection"
            :disabled="getLoadingUploadFileCdg"
            :loading="getLoadingUploadFileCdg"
          >
            Confirmer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--DETAILS DOSSIER-->
    <v-dialog
      v-model="modalDetails"
      max-width="1200px"
      scrollable
      hide-overlay
      persistent
      content-class="custom-vuetify-dialog-insertion"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Historique dossier</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="handleModalDetails('hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card">
          <v-row v-if="selectedItem != null">
            <v-col cols="12" v-if="selectedItem != null" class="mt-1">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center text-dark">Numéro de dossier</th>
                      <th class="text-center text-dark">Nom</th>
                      <th class="text-center text-dark">
                        Prénom
                      </th>
                      <th class="text-center text-dark">
                        Regie
                      </th>
                      <th class="text-center text-dark">
                        Numéro de lot
                      </th>
                      <th class="text-center text-dark">Nom de dépot</th>
                      <th class="text-center text-dark" v-if="phase == 2">
                        Super régie
                      </th>
                      <th class="text-center text-dark">
                        Installateur
                      </th>
                      <th class="text-center text-dark">
                        Organisme
                      </th>
                      <th class="text-center text-dark">
                        Montant
                      </th>
                      <th class="text-center text-dark">
                        Montant HT
                      </th>
                      <th class="text-center text-dark">
                        Coef prime installateur
                      </th>
                      <th class="text-center text-dark">
                        Dossier ID
                      </th>
                      <th class="text-center text-dark">
                        kwh cumac
                      </th>
                      <th class="text-center text-dark">
                        Date dépôt de dossier
                      </th>
                      <th class="text-center text-dark" v-if="phase == 2">
                        Bureau d'etude
                      </th>
                      <th class="text-center text-dark">
                        Version
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, i) in selectedItem.projects"
                      :key="'index_selected_dossier' + i"
                    >
                      <td class="text-center">
                        {{ item.numero_dossier }}
                      </td>
                      <td class="text-center">{{ item.nom1 }}</td>
                      <td class="text-center">{{ item.prenom1 }}</td>
                      <td class="text-center">{{ item.regie }}</td>
                      <td class="text-center">{{ item.numero_de_lot }}</td>
                      <td class="text-center">{{ item.numero_de_depot }}</td>
                      <td class="text-center" v-if="phase == 2">
                        {{ item.super_regie }}
                      </td>
                      <td class="text-center">{{ item.installeur }}</td>
                      <td class="text-center">{{ item.organisme }}</td>
                      <td class="text-center">{{ item.montant_final }}</td>
                      <td class="text-center">{{ item.montant_total_ht }}</td>
                      <td class="text-center">
                        {{ item.coef_prime_installateur }}
                      </td>
                      <td class="text-center">{{ item.dossier_ID }}</td>
                      <td class="text-center">{{ item.kwh_cumac }}</td>
                      <td class="text-center">{{ item.date_depot_dossier }}</td>
                      <td class="text-center" v-if="phase == 2">
                        {{ item.BE_TYPE }}
                      </td>
                      <td class="text-center">{{ item.version }}</td>
                    </tr>
                    <tr>
                      <td class="text-center">
                        {{ selectedItem.new.numero_dossier }}
                      </td>
                      <td
                        class="text-center"
                        :class="{
                          'red-color-style': selectedItem.new.nom1_diff
                        }"
                      >
                        {{ selectedItem.new.nom1 }}
                      </td>
                      <td
                        class="text-center"
                        :class="{
                          'red-color-style': selectedItem.new.prenom1_diff
                        }"
                      >
                        {{ selectedItem.new.prenom1 }}
                      </td>
                      <td
                        class="text-center"
                        :class="{
                          'red-color-style': selectedItem.new.regie_diff
                        }"
                      >
                        {{ selectedItem.new.regie }}
                      </td>
                      <td
                        class="text-center"
                        :class="{
                          'red-color-style': selectedItem.new.numero_de_lot_diff
                        }"
                      >
                        {{ selectedItem.new.numero_de_lot }}
                      </td>
                      <td
                        class="text-center"
                        :class="{
                          'red-color-style':
                            selectedItem.new.numero_de_depot_diff
                        }"
                      >
                        {{ selectedItem.new.numero_de_depot }}
                      </td>
                      <td
                        class="text-center"
                        :class="{
                          'red-color-style': selectedItem.new.super_regie_diff
                        }"
                        v-if="phase == 2"
                      >
                        {{ selectedItem.new.super_regie }}
                      </td>
                      <td
                        class="text-center"
                        :class="{
                          'red-color-style': selectedItem.new.installeur_diff
                        }"
                      >
                        {{ selectedItem.new.installeur }}
                      </td>
                      <td
                        class="text-center"
                        :class="{
                          'red-color-style': selectedItem.new.organisme_diff
                        }"
                      >
                        {{ selectedItem.new.organisme }}
                      </td>
                      <td
                        class="text-center"
                        :class="{
                          'red-color-style': selectedItem.new.montant_final_diff
                        }"
                      >
                        {{ selectedItem.new.montant_final }}
                      </td>
                      <td
                        class="text-center"
                        :class="{
                          'red-color-style':
                            selectedItem.new.montant_total_ht_diff
                        }"
                      >
                        {{ selectedItem.new.montant_total_ht }}
                      </td>
                      <td
                        class="text-center"
                        :class="{
                          'red-color-style':
                            selectedItem.new.coef_prime_installateur_diff
                        }"
                      >
                        {{ selectedItem.new.coef_prime_installateur }}
                      </td>
                      <td
                        class="text-center"
                        :class="{
                          'red-color-style': selectedItem.new.dossier_ID_diff
                        }"
                      >
                        {{ selectedItem.new.dossier_ID }}
                      </td>
                      <td
                        class="text-center"
                        :class="{
                          'red-color-style': selectedItem.new.kwh_cumac_diff
                        }"
                      >
                        {{ selectedItem.new.kwh_cumac }}
                      </td>
                      <td
                        class="text-center"
                        :class="{
                          'red-color-style':
                            selectedItem.new.date_depot_dossier_diff
                        }"
                      >
                        {{ selectedItem.new.date_depot_dossier }}
                      </td>
                      <td
                        class="text-center"
                        :class="{
                          'red-color-style': selectedItem.new.BE_TYPE_diff
                        }"
                        v-if="phase == 2"
                      >
                        {{ selectedItem.new.BE_TYPE }}
                      </td>
                      <td
                        class="text-center"
                        :class="{
                          'red-color-style': selectedItem.new.version_diff
                        }"
                      >
                        {{ selectedItem.new.version }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--RESULTAT FINAL INJECTION-->
    <v-dialog
      v-model="resultInjection"
      max-width="1200px"
      scrollable
      hide-overlay
      persistent
      content-class="custom-vuetify-dialog-insertion"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">RÉSULTAT FINALE D'INJECTION</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="handleResultInjection('hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card">
          <v-row v-if="result != null">
            <v-col
              cols="6"
              v-if="result && result.warning && result.warning.length"
              class="mt-1"
            >
              <span class="title-modal-injection"
                >Liste des dossiers sans agent commercial ({{
                  result.warning.length
                }}): :</span
              >
              <div
                v-for="(i, index) in result.warning"
                :key="'succes-file' + index"
              >
                <span class="mr-2">•</span>
                <span class="item-in-modal-injection">
                  {{ i.numero }} : {{ i.reason }}</span
                >
              </div>
            </v-col>

            <v-col
              cols="6"
              v-if="result && result.ignore && result.ignore.length"
              class="mt-1"
            >
              <span class="title-modal-injection"
                >Liste des dossiers ignoré ({{ result.ignore.length }}) :</span
              >
              <div
                v-for="(i, index) in result.ignore"
                :key="'succes-file' + index"
              >
                <span class="mr-2">•</span>
                <span class="item-in-modal-injection">
                  {{ i.numero }} : {{ i.reason }}</span
                >
              </div>
            </v-col>
            <v-col
              cols="6"
              v-if="result && result.new && result.new.length"
              class="mt-1"
            >
              <span class="title-modal-injection"
                >Liste des nouveaux dossiers injecté ({{ result.new.length }})
                :</span
              >
              <div
                v-for="(i, index) in result.new"
                :key="'succes-file' + index"
              >
                <span class="mr-2">•</span>
                <span class="item-in-modal-injection"> {{ i }} </span>
              </div>
            </v-col>

            <v-col
              cols="6"
              v-if="result && result.replace && result.replace.length"
              class="mt-1"
            >
              <span class="title-modal-injection"
                >Liste des dossiers remplacé ({{ result.replace.length }})
                :</span
              >
              <div
                v-for="(i, index) in result.replace"
                :key="'succes-file' + index"
              >
                <span class="mr-2">•</span>
                <span class="item-in-modal-injection"> {{ i }}</span>
              </div>
            </v-col>

            <v-col
              cols="6"
              v-if="result && result.version && result.version.length"
              class="mt-1"
            >
              <span class="title-modal-injection"
                >Liste des dossiers avec une nouvelle version ({{
                  result.version.length
                }}) :</span
              >
              <div
                v-for="(i, index) in result.version"
                :key="'succes-file' + index"
              >
                <span class="mr-2">•</span>
                <span class="item-in-modal-injection"> {{ i }}</span>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn text @click.prevent="handleResultInjection('hide')">
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'InsertionBDD',
  props: {
    phase: { required: true },
    title: { required: true }
  },
  data() {
    return {
      insertionXls: false,
      file: null,
      selectedItem: null,
      result: null,
      checkAll: 'ignore',
      resultInjection: false,
      uploadFileCdgModal: false,
      modalDetails: false
    };
  },
  computed: {
    ...mapGetters([
      'getLoadingUploadFileCdg',
      'getReturnDataInjection',
      'getErrorUploadFileCdg',
      'checkPermission'
    ])
  },
  methods: {
    ...mapActions([
      'uploadFile',
      'uploadFileTh',
      'CheckuploadFileTh',
      'uploadFileCdg'
    ]),
    handleUploadFileCdg(action, closed) {
      if (action == 'hide') {
        this.uploadFileCdgModal = false;
        if (closed) {
          this.checkAll = 'ignore';
          this.file = null;
          document.getElementById('uploadInput').value = '';
        }
      } else {
        this.uploadFileCdgModal = true;
      }
    },
    handleUploadToExcel(action) {
      if (action == 'hide') {
        this.insertionXls = false;
      } else {
        this.insertionXls = true;
      }
    },
    openModelDetails(item) {
      this.selectedItem = item;
      this.handleModalDetails('show');
    },
    handleModalDetails(action) {
      if (action == 'hide') {
        this.modalDetails = false;
        this.selectedItem = null;
      } else {
        this.modalDetails = true;
      }
    },

    handleFileDrop(e) {
      document.getElementById('uploadInput').value = '';
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      [...droppedFiles].forEach(f => {
        this.file = f;
      });
    },
    handleFileInput(e) {
      let files = e.target.files;
      files = e.target.files;
      if (!files) return;
      [...files].forEach(f => {
        this.file = f;
      });
      document.getElementById('uploadInput').value = '';
    },
    handleResultInjection(action) {
      if (action == 'hide') {
        this.resultInjection = false;
        this.result = null;
        this.checkAll = 'ignore';
        this.file = null;
        document.getElementById('uploadInput').value = '';
      } else {
        this.resultInjection = true;
      }
    },
    async handleUploadFile() {
      if (this.file !== null) {
        const payload = {
          file: this.file,
          phase: this.phase
        };
        const response = await this.uploadFileCdg(payload);
        if (response.succes) {
          this.result = response.data;
          this.handleUploadToExcel('hide');
          this.handleResultInjection('show');
        } else {
          if (this.getErrorUploadFileCdg?.missing) {
            let missing = this.getErrorUploadFileCdg.missing
              ? "<div  style='margin: auto;width: 50%;height: 150px;overflow-x: auto;' class='ModalScrol'><div><h4>Colonnes manquantes :</h4><div><ul> "
              : '';
            if (this.getErrorUploadFileCdg.missing) {
              for (const [key, value] of Object.entries(
                this.getErrorUploadFileCdg.missing
              )) {
                missing = missing + '<li>' + value + '</li>';
              }
            }
            missing = missing + '</ul></div></div>';
            this.$swal.fire({
              background: 'rgb(245 245 252)',
              type: 'error',
              icon: 'warning',
              locale: 'fr',
              html: missing,
              allowOutsideClick: false,
              showCloseButton: true
            });
            this.file = null;
          } else if (this.getErrorUploadFileCdg?.error) {
            this.$swal.fire({
              background: 'rgb(245 245 252)',
              type: 'error',
              icon: 'warning',
              html: this.getErrorUploadFileCdg?.error,
              allowOutsideClick: false,
              showCloseButton: true
            });
            this.file = null;
          } else if (this.getErrorUploadFileCdg?.error406) {
            this.handleUploadToExcel('hide');
            this.handleUploadFileCdg('show', false);
          }
        }
      }
    },
    async injection() {
      const payload = {
        file: this.file,
        phase: this.phase
      };
      if (
        this.getReturnDataInjection &&
        this.getReturnDataInjection.undecided &&
        this.getReturnDataInjection.undecided.length
      ) {
        payload.decisions = this.getReturnDataInjection.undecided.map(item => {
          return { numero: item.numero_dossier, decision: item.decision };
        });
      }
      const response = await this.uploadFileCdg(payload);
      if (response.succes) {
        this.result = response.data;
        this.handleUploadFileCdg('hide', true);
        this.file = null;
        document.getElementById('uploadInput').value = '';
        this.handleResultInjection('show');
      }
    },
    changeAllType() {
      if (
        this.getReturnDataInjection &&
        this.getReturnDataInjection.undecided
      ) {
        this.getReturnDataInjection.undecided.map(
          item => (item.decision = this.checkAll)
        );
      }
    },
    checkDecision() {
      if (
        this.getReturnDataInjection &&
        this.getReturnDataInjection.undecided
      ) {
        if (
          this.getReturnDataInjection.undecided.filter(
            item => item.decision == this.checkAll
          ).length != this.getReturnDataInjection.undecided.length
        )
          this.checkAll = null;
      }
    }
  }
};
</script>
<style lang="scss">
.v-input.checked-global-rapport.v-input--hide-details.theme--light.v-input--selection-controls.v-input--checkbox {
  margin-top: 1px;
  margin-left: 24px;
  color: #704ad1;
}
</style>

<style lang="scss" scoped>
.item-in-modal-injection {
  font-size: 12px;
  font-weight: 700;
}
.title-modal-injection {
  font-weight: 700;
  font-size: 14px;
  font-family: 'Montserrat';
  text-transform: uppercase;
}
table,
th,
td {
  border: 1px solid #0000001f;
}

.box-white {
  z-index: 50;
  width: fit-content;
  padding: 20px 20px 25px 20px;
  background: #ffffff;
  border-radius: 17px;
  width: 100%;
  top: -5%;
  position: relative;
  text-align: initial;
  .box-title-uplaod {
    position: relative;
    width: 215px;
    margin-bottom: 14px;
    padding: 0px 6px;
    .text {
      font-size: 20px;
      font-family: 'Roboto';
      font-weight: bold;
      color: #2f2f2f;
    }
  }
  .container {
    height: 230px;
    width: 100%;
    .file-wrapper {
      text-align: center;
      width: 100%;
      height: 100%;
      vertical-align: middle;
      position: relative;
      overflow: hidden;
      border: 1px dashed #704ad1;
      background-color: #704ad126;
      border-radius: 5px;
      box-shadow: 1px 2px 5px 5px #f5f5f5;
      input {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 5;
      }
      .display {
        display: none;
      }
      .upload_label {
        font-size: 40px;
        position: relative;
        top: 32px;
        .drag-title {
          text-decoration: underline;
          font-size: 15px;
          font-family: 'Montserrat', sans-serif;
          font-weight: 400;
          color: #704ad1;
        }
        .color-black {
          color: #000;
        }
        p {
          font-size: 15px;
        }
        .icon-upload {
          width: 33px;
          height: 33px;
          margin-bottom: 15px;
          color: #704ad1;
        }
        .btn-upload-file {
          font-family: 'Montserrat', sans-serif;
          font-size: 14px;
          font-weight: 400;
          width: 60%;
          border-radius: 29px;
          background-color: #704ad1;
          padding: 11px 20px;
          border: 0;
          color: #fff;
          margin: auto;
        }
      }
    }
  }
  .file-exel-style {
    font-size: 11px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: #000;
    text-decoration: underline;
    position: relative;
    margin-top: 10px;
    span {
      font-weight: 500;
      color: #fe808b;
    }
  }
  .error-upload {
    position: absolute;
    width: 100%;
    font-size: 12px;
    text-align: center;
    bottom: -2%;
    right: 3%;
    z-index: 50;
    color: red;
    .title-error {
      font-weight: 600;
    }
  }
}
.btn-insertion-bdd {
  height: 30px !important;
  min-width: 64px;
  padding: 0 16px;
  box-shadow: none;
  border-radius: 4px;
  color: #fff !important;
  background-color: #704ad1 !important;
  margin-top: 5px;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 11px;
  line-height: 17px;
}
</style>
